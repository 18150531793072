<template>
  <div class="general-report-by-flat" style="height: 100%">
    <v-menu
        v-model="showExpand"
        :position-x="x"
        :position-y="y"
        absolute
        offset-y
        class="non-printable"
    >
      <v-list nav dense min-width="140"
              class="non-printable"
      >
        <v-list-item @click="toggleExpand(-1)">
          <v-list-item-title>Усі рівні</v-list-item-title>
        </v-list-item>
        <v-list-item
            v-for="(item, index) in max_lvl+1"
            :key="`index-menu-${index}`"
            @click="toggleExpand(item-1)"
        >
          <v-list-item-title>{{ `Рівень ${item-1}` }}</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
    <v-navigation-drawer
        class="non-printable"
        app
        :width="navWidth"
        right
        v-model="setting_dialog"
        temporary
        @input="onNavInput"
    >
      <v-btn
          @click="closeSettings"
          depressed
      >Приховати налаштування
      </v-btn>
      <v-row>
        <v-col cols="12">
          <v-card tile elevation="0">
            <v-card-text class="pt-2">
              <v-tabs fixed-tabs color="grey darken-2">
                <v-tab>Основні</v-tab>
                <v-tab>Групування</v-tab>
                <v-tab-item class="mt-3">
                  <v-row>
                    <v-col cols="12">
                      <v-switch v-model="filters.unfolded_balance" label="Розгорнуте сальдо" class="mt-0" color="success"/>
                    </v-col>
                  </v-row>
                </v-tab-item>
                <v-tab-item class="mt-3">
                  <v-row>
                    <v-col cols="12">
                      <v-card
                          v-for="(item, idx) in grouping"
                          :key="`gr-idx-${idx}`"
                          style="display: flex; align-items: center"
                          class="ma-2 py-0"
                          elevation="3"
                      >
                        <div style="flex: 0 0 60px">
                          <v-checkbox color="success" class="ml-3" v-model="item.on"/>
                        </div>
                        <div style="flex: 1; font-weight: 400; font-size: .96rem">
                          {{ item.text }}
                        </div>
                        <div style="flex: 0 0 80px">
                          <v-btn icon @click="pushUp(item)">
                            <v-icon>mdi-arrow-up</v-icon>
                          </v-btn>
                          <v-btn icon @click="pushDown(item)">
                            <v-icon>mdi-arrow-down</v-icon>
                          </v-btn>
                        </div>
                      </v-card>
                    </v-col>
                  </v-row>
                </v-tab-item>
              </v-tabs>

            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-navigation-drawer>
    <v-dialog v-model="report_dialog.dialog" fullscreen class="media-print">
      <PrintReport @closeModal="closeReportDialog"
                   :payload_int="report_dialog.accounting_id"
                   :payload_date_start="date_start"
                   :payload_date_end="date_end"
                   :auto_generate="true"
                   :component_name="report_dialog.component_name"/>
    </v-dialog>
    <v-menu
        v-model="show_report_menu"
        :position-x="x1"
        :position-y="y1"
        absolute
        offset-y
    >
      <v-list dense nav>
        <v-subheader>
          Оберіть необхідний звіт
        </v-subheader>
        <v-divider></v-divider>
        <v-list-item dense style="font-size: .8rem"
                     @click="openReportDialog(
                      'accounting_main_report_by_code',
                           'Оборотно-сальдова відомість по рахунку',
                           )"
        >
          <v-list-item-icon class="mr-4">
            <v-icon color="teal" size="20">
              mdi-poll
            </v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            Оборотно-сальдова відомість по рахунку
          </v-list-item-content>
        </v-list-item>
        <v-divider/>
        <v-list-item dense style="font-size: .8rem"
                     @click="openReportDialog(
                      'accounting_card_report_by_code',
                           'Картка рахунку',
                           )"
        >
          <v-list-item-icon class="mr-4">
            <v-icon color="teal" size="20">
              mdi-poll
            </v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            Картка рахунку
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-menu>
    <div class="page page-a4" style="height: auto !important; min-height: 215mm;"
         :style="`zoom: ${scale / 100}`"
    >
      <div class="report table-report" :style="`transform: scale(${zoom}); transform-origin: top left;`"
           style="height: 100%">
        <table>
          <caption>
            <div class="report-section report-header" style="padding-top: 2px; flex-wrap: wrap;">
              <div class="rp-col-12 header-underline">
                <div class="rp-row rp-text-center" style="font-size: 1.1rem; font-weight: 500">
                  {{ organization.full_name }} (Оборотно-сальдова відомість)
                </div>
              </div>
              <div class="rp-col-12"></div>
              <div class="rp-col-12 text-center mt-2">
                {{ print_data.organization_details_with_account }}
              </div>
              <div class="rp-col-12 text-left mt-8">
                Дата формування: {{ getCurrentDate() | formatDate }} {{ getPrintDateRange(date_start, date_end) }}
              </div>
            </div>
            <div class="empty-row report-header" style="height: 20px;"></div>
          </caption>
          <thead>
            <tr id="header">
              <th rowspan="2" style="min-width: 260px; width: 260px !important;">
                Рахунок
              </th>
              <th colspan="2" rowspan="1" style="min-width: 170px; width: 170px; padding: 0;">
                Сальдо на початок періоду
              </th>
              <th colspan="2" rowspan="1" style="min-width: 170px; width: 170px; padding: 0;">
                Обороти за період
              </th>
              <th colspan="2" rowspan="1" style="min-width: 170px; width: 170px; padding: 0;">
                Сальдо на кінець періоду
              </th>
            </tr>
            <tr>
              <th colspan="1" rowspan="1" style="min-width: 80px; width: 80px; padding: 0;">
                Дебет
              </th>
              <th colspan="1" rowspan="1" style="min-width: 80px; width: 80px; padding: 0;">
                Кредит
              </th>
              <th colspan="1" rowspan="1" style="min-width: 80px; width: 80px; padding: 0;">
                Дебет
              </th>
              <th colspan="1" rowspan="1" style="min-width: 80px; width: 80px; padding: 0;">
                Кредит
              </th>
              <th colspan="1" rowspan="1" style="min-width: 80px; width: 80px; padding: 0;">
                Дебет
              </th>
              <th colspan="1" rowspan="1" style="min-width: 80px; width: 80px; padding: 0;">
                Кредит
              </th>
            </tr>
          </thead>

          <tbody v-for="(item, idx) in items"
                 :key="`group-${item.row_idx}`"
                 :class="`${item.items ? item.class + ' details' : item.class}`"
                 :style="`${item.items ? 'display: none;' : ''}`"
                 :data-level="`level-${item.level}`"
                 :data-button="`button-${idx}`"
          >
          <template v-if="!item.items && by_grouping">
            <tr :class="`bg-level-${item.level}`" @contextmenu="showReportMenu($event, item)">
              <td class="grow" colspan="1"
                  :style="`padding-left: ${item.level === 1 ? 8 : (item.level - 1) * 16}px`"
              >
                <v-icon size="14" class="mr-1" color="grey darken-3"
                        @click.stop="showRows(item.class, `button-${idx}`)"
                        :id="`button-${idx}`"
                >
                  {{ getCollapseIcon(item.level) }}
                </v-icon>
                {{ item.group_value }}
              </td>
              <td style="width: 80px; min-width: 80px">{{ item.balance_start_debt | formatNumber }}</td>
              <td style="width: 80px; min-width: 80px">{{ item.balance_start_cred | formatNumber }}</td>
              <td style="width: 80px; min-width: 80px">{{ item.turnover_debt | formatNumber }}</td>
              <td style="width: 80px; min-width: 80px">{{ item.turnover_cred | formatNumber }}</td>
              <td style="width: 80px; min-width: 80px">{{ item.balance_end_debt | formatNumber }}</td>
              <td style="width: 80px; min-width: 80px">{{ item.balance_end_cred | formatNumber }}</td>
            </tr>
          </template>
          <template v-else-if="!item.items && !by_grouping">
            <tr @contextmenu="showReportMenu($event, item)">
              <td style="min-width: 260px; width: 260px !important"
              >{{ item.account }}</td>
              <td style="min-width: 80px; width: 80px">{{ item.balance_start_debt | formatNumber }}</td>
              <td style="min-width: 80px; width: 80px">{{ item.balance_start_cred | formatNumber }}</td>
              <td style="min-width: 80px; width: 80px">{{ item.turnover_debt | formatNumber }}</td>
              <td style="min-width: 80px; width: 80px">{{ item.turnover_cred | formatNumber }}</td>
              <td style="min-width: 80px; width: 80px">{{ item.balance_end_debt | formatNumber }}</td>
              <td style="min-width: 80px; width: 80px">{{ item.balance_end_cred | formatNumber }}</td>
            </tr>
          </template>
          <template v-else>
            <tr
                v-for="subitem in item.items"
                :key="`item-${subitem.row_idx}`"
                @contextmenu="showReportMenu($event, item)"
            >
              <td style="min-width: 260px; cursor: pointer;"
                  :style="`padding-left: ${item.level * 18}px`"
              >{{ subitem.account }}</td>
              <td style="min-width: 80px; width: 80px">{{ subitem.balance_start_debt | formatNumber }}</td>
              <td style="min-width: 80px; width: 80px">{{ subitem.balance_start_cred | formatNumber }}</td>
              <td style="min-width: 80px; width: 80px">{{ subitem.turnover_debt | formatNumber }}</td>
              <td style="min-width: 80px; width: 80px">{{ subitem.turnover_cred | formatNumber }}</td>
              <td style="min-width: 80px; width: 80px">{{ subitem.balance_end_debt | formatNumber }}</td>
              <td style="min-width: 80px; width: 80px">{{ subitem.balance_end_cred | formatNumber }}</td>
            </tr>
          </template>
          </tbody>
          <tr class="bg-level-1" style="text-align: center">
            <td class="grow" colspan="1" style="font-weight: 530; text-align: right;">Всього: </td>
            <td style="width: 80px; min-width: 80px">{{ getTotal('balance_start_debt') | formatNumber }}</td>
            <td style="width: 80px; min-width: 80px">{{ getTotal('balance_start_cred') | formatNumber }}</td>
            <td style="width: 80px; min-width: 80px">{{ getTotal('turnover_debt') | formatNumber }}</td>
            <td style="width: 80px; min-width: 80px">{{ getTotal('turnover_cred') | formatNumber }}</td>
            <td style="width: 80px; min-width: 80px">{{ getTotal('balance_end_debt') | formatNumber }}</td>
            <td style="width: 80px; min-width: 80px">{{ getTotal('balance_end_cred') | formatNumber }}</td>
          </tr>
        </table>
      </div>
    </div>
  </div>
</template>

<script>

import accountingReportAPI from "@/utils/axios/accounting/reports"
import ReportHouseAccountingComponentMixin from "@/mixins/report_house_accounting";
import {ALERT_SHOW} from "@/store/actions/alert";
import {
  LANDSCAPE_ZOOM_WIDTH,
  PORTRAIT_ZOOM_WIDTH
} from "@/utils/reports";
import reportAPI from "@/utils/axios/reports";

export default {
  name: "AccountingMainReport",
  mixins: [ReportHouseAccountingComponentMixin],
  components: {
    PrintReport: () => import("@/components/PrintReport")
  },
  computed: {
    navWidth() {
      if (this.$vuetify.breakpoint.xs) {
        return '80%'
      }

      if (this.$vuetify.breakpoint.sm) {
        return '60%'
      }

      return '35%'
    }
  },
  data() {
    return {
      show_report_menu: false,
      showExpand: false,
      report_dialog: {
        dialog: false,
        component_name: '',
        report_title: '',
        accounting_id: null
      },
      x1: 0,
      y1: 0,
      report_row: null,
      filters: {
        unfolded_balance: false
      },
      zoom_width: PORTRAIT_ZOOM_WIDTH,
      by_grouping: false,
      grouping: [
        { text: 'Батьківський рахунок', value: 'parent_account_code', on: false },
      ],
    }
  },
  methods: {
    showReportMenu (e, item) {
      e.preventDefault()
      this.show_report_menu = false
      this.x1 = e.clientX
      this.y1 = e.clientY
      this.report_row = item
      this.$nextTick(() => {
        this.show_report_menu = true
      })
    },
    openReportDialog(component_name, report_title) {
      const accounting_id = this.report_row.account_id
      if (!component_name) {
        this.report_dialog.dialog = false
        this.report_dialog.component_name = ''
        this.report_dialog.report_title = ''
        this.report_dialog.accounting_id = null
      }
      this.report_dialog.component_name = component_name
      this.report_dialog.report_title = report_title
      this.report_dialog.dialog = true
      this.report_dialog.accounting_id = accounting_id
    },
    closeReportDialog() {
      this.report_dialog.dialog = false
      this.report_dialog.component_name = ''
      this.report_dialog.report_title = ''
      this.report_dialog.accounting_id = null
    },
    generate_email_send() {
      if (this.date_start && this.date_end) {
        this.$emit('email_loading_status', true)
        const grouping = this.grouping.filter(i => i.on).map(item => item.value)
        const payload = {
          date_start: this.date_start,
          date_end: this.date_end,
          filters: Object.assign({}, this.filters, {grouping: grouping}),
          email: this.email_address,
        }
        accountingReportAPI.general_report_email(payload)
            .then(response => response.data)
            .then(data => {
              this.$emit('email_loading_status', false)
              this.$store.dispatch(ALERT_SHOW, {color: data.status === 'OK' ? 'success' : 'error', text: data.text})
            })
            .catch(err => {
              const error = err.response.data.detail;
              this.$emit('email_loading_status', false)
              this.$store.dispatch(ALERT_SHOW, {text: error, color: 'error lighten-1'})
            })
            .finally(() => {
              this.$emit('generate_email')
            })
      } else {
        this.$store.commit(ALERT_SHOW, {
          text: 'Період має бути заповнений',
          color: 'error'
        })
      }
    },
    generate_xlsx_file() {
      if (this.date_start && this.date_end) {
        this.$emit('xlsx_loading_status', true)
        const grouping = this.grouping.filter(i => i.on).map(item => item.value)
        const payload = {
          date_start: this.date_start,
          date_end: this.date_end,
          filters: Object.assign({}, this.filters, {grouping: grouping})
        }
        accountingReportAPI.general_report_xlsx(payload)
            .then(response => {
              const url = window.URL.createObjectURL(new Blob([response.data]))
              const link = document.createElement('a');
              const file_name = `general_report_xlsx${this.formatDate(this.date_start, 'MM_YYYY')}_${this.formatDate(this.date_end, 'MM_YYYY')}.xlsx`
              link.href = url;
              link.setAttribute('download', file_name); //or any other extension
              document.body.appendChild(link);
              link.click();
              this.$emit('xlsx_loading_status', false)
            })
            .catch(err => {
              const error = err.response.data.detail;
              this.$emit('xlsx_loading_status', false)
              this.$store.dispatch(ALERT_SHOW, {text: error, color: 'error lighten-1'})
            })
            .finally(() => {
              this.$emit('generate_xlsx')
            })
      }
    },
    generate_report() {
      if (this.date_start && this.date_end) {
        this.$emit('report_loading_status', true)
        const grouping = this.grouping.filter(i => i.on).map(item => item.value)
        const payload = {
          date_start: this.date_start,
          date_end: this.date_end,
          filters: Object.assign({}, this.filters, {grouping: grouping})
        }
        accountingReportAPI.general_report(payload)
            .then(response => response.data)
            .then(data => {
              let max_lvl = 0
              let by_grouping = false
              data.rows.forEach(row => {
                if (row.level >= max_lvl) {
                  max_lvl = row.level
                  if (row['items']) {
                    by_grouping = true
                  }

                }
              })
              this.by_grouping = by_grouping
              this.max_lvl = max_lvl
              this.items = data.rows
            })
            .catch(err => {
              const error = err.response.data.detail;
              this.$store.dispatch(ALERT_SHOW, {text: error, color: 'error lighten-1'})
            })
            .finally(() => {
              this.$emit('generate_report', !!this.items)
              this.$emit('report_loading_status', false)
              this.$nextTick(() => {
                this.expand = false
                this.toggleExpand(0)
                this.getOnFullPage()
              })
            })
      } else {
        this.$store.commit(ALERT_SHOW, {text: 'Місяць - має бути заповнений', color: 'error'})
      }
    },
    getTotal(col) {
      let total = 0

      if (this.by_grouping) {
        total = this.items.filter(item => item.level === 1 && !item.items).reduce((acc, r) => {
          return acc + r[col]
        }, 0)
      } else {
        total = this.items.filter(item => !item.level).reduce((acc, r) => {
          return acc + r[col]
        }, 0)
      }
      return total
    },
    getOnFullPage() {
      let width = 12
      const first_row = document.getElementById('header')
      first_row.childNodes.forEach(item => width += (item.clientWidth || 0))
      const gen_element = document.getElementsByClassName('report')[0]
      const scrollWidth = width * this.zoom
      const client_width = gen_element.clientWidth * this.zoom
      let zoom_width = 0
      let zoom = client_width / scrollWidth
      if (zoom === 0) zoom = 1
      if (zoom > 1) {
        zoom = 1
        zoom_width = LANDSCAPE_ZOOM_WIDTH
      }

      if (zoom !== 1) {
        zoom_width = 1 / zoom * LANDSCAPE_ZOOM_WIDTH
      }

      this.zoom = zoom
      this.zoom_width = zoom_width
    },
  },
  created() {
    reportAPI.report_settings("accounting_main_report")
        .then(response => response.data)
        .then(data => {
          const server_filters = JSON.parse(data)
          Object.keys(this.filters).forEach(key => {
            if (server_filters[key] !== undefined) {
              this.filters[key] = server_filters[key]
            }
          })
          if (server_filters['grouping'] && this.grouping) {
            this.grouping.forEach(g => {
              g.on = !!server_filters.grouping.includes(g.value);
            })
          }
        })
        .finally(() => {
          this.$nextTick(() => this.getOnFullPage())
        })
  }
}
</script>

<style lang="scss" scoped>
//.page-a4-landscape-auto {
//  width: 1160px !important;
//  position: relative;
//}


//@media print {
//  * {
//    float: none !important;
//    clear: both;
//  }
//
//  .pages {
//    height: auto !important;
//    overflow: visible !important;
//  }
//
//  .page {
//    visibility: visible !important;
//    margin: 0 2px !important;
//  }
//
//  @page {
//    margin: 0 0 5mm 0 !important;
//  }
//}

</style>